import { React, useRef } from "react";
import Img1 from "../images/backgrounds/main-slider-1-1.jpg";
import Img2 from "../images/backgrounds/main-slider-1-2.jpg";
import Img3 from "../images/backgrounds/main-slider-1-3.jpg";
// import required modules
import { Autoplay, Navigation, EffectFade } from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
const Homehero = () => {
  const swiperRef = useRef();
  const Content = [
    {
      img: Img1,
      title: "Modern Residential Apartments",
      name: "34000 sqft",
      description: "Best Price 2 Bed Room Apartments",
    },
    {
      img: Img2,
      title: "Modern Residential Apartments2",
      name: "34000 sqft",
      description: "Best Price 2 Bed Room Apartments",
    },
    {
      img: Img3,
      title: "Modern Residential Apartments3",
      name: "34000 sqft",
      description: "Best Price 2 Bed Room Apartments",
    },
  ];
  console.log(Content.img);
  return (
    <>
      <section className="main-slider clearfix">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 8000}}
          speed={1500}
          loopFillGroupWithBlank={true}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation, EffectFade, Autoplay]}
          className="swiper-container thm-swiper__slider"
        >
          {Content.map((e) => (
            <SwiperSlide>
              <div
                className="image-layer"
                style={{ backgroundImage: `url(${e.img})` }}
              ></div>
              <div className="main-slider__overly"></div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="main-slider__content">
                      <h2 className="main-slider__title">{e.title}</h2>
                      <h4 className="main-slider__sub-title">
                        <span>{e.name}</span>
                        {e.description}{" "}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="main-slider__nav">
          <div
            className="swiper-button-prev"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <i className="icon-left-arrow"></i>
          </div>
          <div
            className="swiper-button-next"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <i className="icon-right-arrow"></i>
          </div>
        </div>
      </section>
    </>
  );
};
export default Homehero;
