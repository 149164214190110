import React from "react";
import Homehero from "../component/Homehero";
import Image1 from "../images/resources/about-1-2.png";
import Image2 from "../images/resources/about-1-1.jpg";
import Image3 from "../images/shapes/about-one-shape-1.png";
import Image4 from "../images/shapes/about-one-shape-2.png";
import Image5 from "../images/backgrounds/counter-one-bg-img-1.png";
import Image17 from "../images/backgrounds/apartment-one-bg.jpg";
import Image18 from "../images/resources/apartment-1-1.jpg";
import Image19 from "../images/resources/apartment-1-2.jpg";
import Image20 from "../images/resources/apartment-1-3.jpg";
import Image21 from "../images/resources/apartment-1-4.jpg";
// import Image24 from "../images/backgrounds/contact-form-bg-img-1.jpg";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Gallery from "../component/Gallery";
import Testimonials from "../component/Testimonials";
import Brand from "../component/Brand";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
import Image13 from "../images/resources/cta-two-bg-img-1.jpg";
import Image14 from "../images/backgrounds/about-building-bg.png";
import Image15 from "../images/resources/about-building-img-1.jpg";
import Contact from "../component/Contact";
const Home = () => {
  return (
    <>
      <Homehero />

      <section className="about-one">
        <div className="about-one__img-two">
          <img src={Image1} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <Fade left>
              <div className="col-xl-6">
                <div className="about-one__left">
                  <div
                    className="about-one__img-box wow slideInLeft"
                    data-wow-delay="100ms"
                    data-wow-duration="2500ms"
                  >
                    <div className="about-one__img">
                      <img src={Image2} alt="" />
                    </div>
                    <div className="about-one__shape-1 shape-mover">
                      <img src={Image3} alt="" />
                    </div>
                    <div className="about-one__big-text">Deluxe</div>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="col-xl-6">
                <div className="about-one__right">
                  <div className="section-title text-left">
                    <span className="section-title__tagline">
                      our building overview
                    </span>
                    <h2 className="section-title__title">
                      The high Premium quality luxury apartments
                    </h2>
                  </div>
                  <p className="about-one__text-1">
                    Many important brands have given us their trust
                  </p>
                  <p className="about-one__text-2">
                    Interior of volumes, space, air, proportion, with certain
                    light and mood. These interiors are meant to last forever.
                    You need to be sure there isn't anything embarrassing hidden
                    in the middle of text.
                  </p>
                  <ul className="about-one__points-list list-unstyled">
                    <li>
                      <div className="about-one__points-list-content">
                        <div className="icon">
                          <span className="fa fa-check"></span>
                        </div>
                        <div className="text">
                          <h3>Water Storage</h3>
                        </div>
                      </div>
                      <p className="about-one__points-text">
                        Lorem ipsum dolor sited amet consectetur notted.
                      </p>
                    </li>
                    <li>
                      <div className="about-one__points-list-content">
                        <div className="icon">
                          <span className="fa fa-check"></span>
                        </div>
                        <div className="text">
                          <h3>solar systems</h3>
                        </div>
                      </div>
                      <p className="about-one__points-text">
                        Lorem ipsum dolor sited amet consectetur notted.
                      </p>
                    </li>
                  </ul>
                  <div className="about-one__btn-box">
                    <div className="about-one__shape-2 float-bob-x">
                      <img src={Image4} alt="" />
                    </div>
                    <Fade bottom>
                      <Link to="/" className="about-one__btn thm-btn">
                        More details
                      </Link>
                    </Fade>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <div className="counter-one">
        <div
          className="counter-one__bg float-bob-x"
          style={{ backgroundImage: `url(${Image5})` }}
        ></div>
        <div className="container">
          <div className="row">
            <Fade bottom>
              {/* <!--Counter One Single Start--> */}
              <div
                className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div className="counter-one__single">
                  <div className="counter-one__top">
                    <div className="counter-one__shape"></div>
                    <div className="counter-one__icon">
                      <span className="icon-ruler"></span>
                    </div>
                  </div>
                  <div className="counter-one__count-box">
                    <h3 className="odometer">
                      <CountUp end={1200} redraw={true}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </h3>
                    <p className="counter-one__text">HAPPY CUSTOMER</p>
                  </div>
                </div>
              </div>
            </Fade>
            {/* <!--Counter One Single End--> */}
            <Fade bottom>
              {/* <!--Counter One Single Start--> */}
              <div
                className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="200ms"
              >
                <div className="counter-one__single">
                  <div className="counter-one__top">
                    <div className="counter-one__shape"></div>
                    <div className="counter-one__icon">
                      <span className="icon-car-parking"></span>
                    </div>
                  </div>
                  <div className="counter-one__count-box">
                    <h3 className="odometer">
                      <CountUp end={100} redraw={true}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </h3>
                    <p className="counter-one__text">CURRENT CUSTOMER</p>
                  </div>
                </div>
              </div>
            </Fade>

            {/* <!--Counter One Single End--> */}
            <Fade bottom>
              {/* <!--Counter One Single Start--> */}
              <div
                className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="300ms"
              >
                <div className="counter-one__single">
                  <div className="counter-one__top">
                    <div className="counter-one__shape"></div>
                    <div className="counter-one__icon">
                      <span className="icon-condominium"></span>
                    </div>
                  </div>
                  <div className="counter-one__count-box">
                    <h3 className="odometer">
                      <CountUp end={500} redraw={true}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </h3>
                    <p className="counter-one__text">SATISFIED CUSTOMER</p>
                  </div>
                </div>
              </div>
            </Fade>

            {/* <!--Counter One Single End--> */}
            {/* <!--Counter One Single Start--> */}
            <Fade bottom>
              <div
                className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="400ms"
              >
                <div className="counter-one__single">
                  <div className="counter-one__top">
                    <div className="counter-one__shape"></div>
                    <div className="counter-one__icon">
                      <span className="icon-bedroom"></span>
                    </div>
                  </div>
                  <div className="counter-one__count-box">
                    <h3 className="odometer">
                      <CountUp end={300} redraw={true}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </h3>
                    <p className="counter-one__text">YET TO BOOK WITH US</p>
                  </div>
                </div>
              </div>
            </Fade>

            {/* <!--Counter One Single End--> */}
          </div>
        </div>
      </div>

      <section className="apartment-one">
        <div
          className="apartment-one__bg"
          style={{ backgroundImage: `url(${Image17})` }}
        ></div>
        <div className="container">
          <div className="section-title text-center">
            <Swiper
              spaceBetween={7}
              slidesPerView={2}
              loop={true}
              autoplay={{ delay: 500 }}
              speed={1400}
              loopFillGroupWithBlank={true}
              modules={[Autoplay]}
              className="swiper-container thm-swiper__slider"
            >
              <SwiperSlide>
                <h1 className="font-logo">MOST POPULAR APPARTMENTS</h1>
              </SwiperSlide>
              <SwiperSlide>
                <h1 className="font-logo">MOST POPULAR APPARTMENTS</h1>
              </SwiperSlide>
              <SwiperSlide>
                <h1 className="font-logo">MOST POPULAR APPARTMENTS</h1>
              </SwiperSlide>
              <SwiperSlide>
                <h1 className="font-logo">MOST POPULAR APPARTMENTS</h1>
              </SwiperSlide>
              <SwiperSlide>
                <h1 className="font-logo">MOST POPULAR APPARTMENTS</h1>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="row">
            {/* <!--Apartment One Single Start--> */}
            <Fade bottom>
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="apartment-one__single">
                  <div className="apartment-one__img-box">
                    <div className="apartment-one__img">
                      <img src={Image18} alt="" />
                    </div>
                    <div className="apartment-one__content">
                      <div className="apartment-one__title-box">
                        <h3 className="apartment-one__title">
                          <Link to="/">
                            Studio
                            <br /> apartment
                          </Link>
                        </h3>
                      </div>
                      <div className="apartment-one__arrow">
                        <Link to="/">
                          <span className="icon-right-arrow"></span>
                        </Link>
                      </div>
                    </div>
                    <div className="apartment-one__btn-box">
                      <Link to="/" className="thm-btn apartment-one__btn">
                        120 m2
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
            {/* <!--Apartment One Single End--> */}
            {/* <!--Apartment One Single Start--> */}
            <Fade bottom>
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="apartment-one__single">
                  <div className="apartment-one__img-box">
                    <div className="apartment-one__img">
                      <img src={Image19} alt="" />
                    </div>
                    <div className="apartment-one__content">
                      <div className="apartment-one__title-box">
                        <h3 className="apartment-one__title">
                          <Link to="">
                            Luxury
                            <br /> apartment
                          </Link>
                        </h3>
                      </div>
                      <div className="apartment-one__arrow">
                        <Link to="">
                          <span className="icon-right-arrow"></span>
                        </Link>
                      </div>
                    </div>
                    <div className="apartment-one__btn-box">
                      <Link to="" className="thm-btn apartment-one__btn">
                        120 m2
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--Apartment One Single End--> */}
            </Fade>
            {/* <!--Apartment One Single Start--> */}
            <Fade bottom>
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="apartment-one__single">
                  <div className="apartment-one__img-box">
                    <div className="apartment-one__img">
                      <img src={Image20} alt="" />
                    </div>
                    <div className="apartment-one__content">
                      <div className="apartment-one__title-box">
                        <h3 className="apartment-one__title">
                          <Link to="">
                            Deluxe
                            <br /> apartment
                          </Link>
                        </h3>
                      </div>
                      <div className="apartment-one__arrow">
                        <Link to="">
                          <span className="icon-right-arrow"></span>
                        </Link>
                      </div>
                    </div>
                    <div className="apartment-one__btn-box">
                      <Link to="" className="thm-btn apartment-one__btn">
                        120 m2
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--Apartment One Single End--> */}
            </Fade>

            <Fade bottom>
              {/* <!--Apartment One Single Start--> */}
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="apartment-one__single">
                  <div className="apartment-one__img-box">
                    <div className="apartment-one__img">
                      <img src={Image21} alt="" />
                    </div>
                    <div className="apartment-one__content">
                      <div className="apartment-one__title-box">
                        <h3 className="apartment-one__title">
                          <Link to="">
                            one room
                            <br /> apartment
                          </Link>
                        </h3>
                      </div>
                      <div className="apartment-one__arrow">
                        <Link to="">
                          <span className="icon-right-arrow"></span>
                        </Link>
                      </div>
                    </div>
                    <div className="apartment-one__btn-box">
                      <Link to="" className="thm-btn apartment-one__btn">
                        120 m2
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--Apartment One Single End--> */}
            </Fade>
          </div>
        </div>
      </section>
      <section className="cta-two cta-four">
        <div
          className="cta-two__bg"
          style={{ backgroundImage: `url(${Image13})` }}
        >
          <div id="jarallax-container-0">
            <div style={{ backgroundImage: `url(${Image13})` }}></div>
          </div>
        </div>
        <div className="cta-two__inner">
          <h3 className="cta-two__title">YOU CAN BOOK NOW & PAY LATER</h3>
          <p className="cta-two__text">
            Lorem ipsum dolor sit amet, cotur adiping elit
          </p>
          <p className="cta-two__text">
            Lorem ipsum dolor sit amet, cotur adiping elit
          </p>
          <p className="cta-two__text">
            Lorem ipsum dolor sit amet, cotur adiping elit
          </p>
          <div className="cta-two__btn-box">
            <a
              href="https://apartmentsde-luxe.zeevou.direct/"
              className="cta-two__btn thm-btn"
            >
              Book Now
            </a>
          </div>
        </div>
      </section>
      {/* <section className="why-choose-one">
        <div
          className="why-choose-one__bg"
          style={{ backgroundImage: `url(${Image15})` }}
        ></div>
        <div
          className="why-choose-one__shape-2 wow slideInLeft"
          data-wow-delay="100ms"
          data-wow-duration="2500ms"
        >
          <img src={Image14} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <Fade left>
              <div className="col-xl-6 col-lg-6">
                <div className="why-choose-one__left">
                  <div className="why-choose-one__inner">
                    <div className="why-choose-one__video-link">
                      <Link to="#" className="video-popup">
                        <div className="why-choose-one__video-icon">
                          <span className="fa fa-play"></span>
                          <i className="ripple"></i>
                        </div>
                      </Link>
                      <div className="why-choose-one__shape-1 float-bob-x">
                        <img src={Image16} alt="" />
                      </div>
                    </div>
                    <h3 className="why-choose-one__title">
                      Why you should choose
                      <br /> our building & apartments
                    </h3>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="col-xl-6 col-lg-6">
                <div className="why-choose-one__right">
                  <div className="why-choose-one__points-box">
                    <ul className="why-choose-one__points-list list-unstyled">
                      <li>
                        <div className="icon">
                          <span className="fa fa-check"></span>
                        </div>
                        <div className="text">
                          <p>Water Storage</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="fa fa-check"></span>
                        </div>
                        <div className="text">
                          <p>Solar Systems</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="fa fa-check"></span>
                        </div>
                        <div className="text">
                          <p>Swimming Pools</p>
                        </div>
                      </li>
                    </ul>
                    <ul className="why-choose-one__points-list why-choose-one__points-list-2 list-unstyled">
                      <li>
                        <div className="icon">
                          <span className="fa fa-check"></span>
                        </div>
                        <div className="text">
                          <p>Fire Exiting</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="fa fa-check"></span>
                        </div>
                        <div className="text">
                          <p>Playgrounds</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="fa fa-check"></span>
                        </div>
                        <div className="text">
                          <p>Fitness Club</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section> */}

      <Brand />
      <section className="about-building" style={{ padding: " 120px 0 0px" }}>
        <div
          className="about-building__bg"
          style={{ backgroundImage: `url(${Image14})` }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="about-building__left">
                <div className="about-building__img">
                  <img src={Image15} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about-building__right">
                <div className="section-title text-left">
                  <h2 className="section-title__title">
                    Hassle free self <br /> check-in
                  </h2>
                </div>
                <p className="about-building__text-1">
                  There are many variations of passages of psum available, but
                  the majority have suffered alteration in some form, by
                  injected humour, or randomised words which don't look even
                  slightly believable.
                </p>
                <p className="about-building__text-2">
                  If you are going to use a passage of you need to be sure there
                  isn't anything embarrassing hidden in the middle of text.
                </p>
                <a
                  class="cta-two__btn thm-btn mt-2"
                  href="https://apartmentsde-luxe.zeevou.direct/"
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="apartment-one">
        <div
          className="apartment-one__bg"
          style={{ backgroundImage: `url(${Image17})` }}
        ></div>
        <div className="container">
          <div className="section-title text-center marquee">
            <div class="textanimation">
              <div class="element">
                <span>D</span>
                <span>E</span>
                <span>-</span>
                <span>L</span>
                <span>U</span>
                <span>X</span>
                <span>E</span>
                <span>.</span>
                <span>A</span>
                <span>C</span>
                <span>C</span>
                <span>O</span>
                <span>M</span>
                <span>M</span>
                <span>O</span>
                <span>D</span>
                <span>A</span>
                <span>T</span>
                <span>I</span>
                <span>O</span>
                <span>N</span>
                <span>.</span>
                <span>D</span>
                <span>E</span>
                <span>-</span>
                <span>L</span>
                <span>U</span>
                <span>X</span>
                <span>E</span>
              </div>
              <div class="element">
                <span>D</span>
                <span>E</span>
                <span>-</span>
                <span>L</span>
                <span>U</span>
                <span>X</span>
                <span>E</span>
                <span>.</span>
                <span>A</span>
                <span>C</span>
                <span>C</span>
                <span>O</span>
                <span>M</span>
                <span>M</span>
                <span>O</span>
                <span>D</span>
                <span>A</span>
                <span>T</span>
                <span>I</span>
                <span>O</span>
                <span>N</span>
                <span>.</span>
                <span>D</span>
                <span>E</span>
                <span>-</span>
                <span>L</span>
                <span>U</span>
                <span>X</span>
                <span>E</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Gallery />
      <Testimonials />
      <Contact />
    </>
  );
};

export default Home;
