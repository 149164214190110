import React from "react";
import Brand from "../component/Brand";
import PageHeader from "../component/PageHeader";
import Testimonials from "../component/Testimonials";
import Image1 from "../images/backgrounds/about.jpg";
import Image2 from "../images/resources/feature-1-1.jpg";
import Image3 from "../images/resources/feature-1-2.jpg";
import Image4 from "../images/resources/values-1-1.jpg";
import Image5 from "../images/shapes/values-one-shape-1.png";
import Image6 from "../images/resources/values-1-2.jpg";
import T1 from "../images/team/team-1-1.jpg";
import T2 from "../images/team/team-1-2.jpg";
import T3 from "../images/team/team-1-3.jpg";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

import Shape1 from "../images/shapes/new-concept-two-shape-1.png";
import Shape2 from "../images/shapes/new-concept-two-shape-2.png";
import Contact from "../component/Contact";
const Aboutus = () => {
  return (
    <>
      <PageHeader img={Image1} heading="About Us" />
      <section className="new-concept-two">
        <div className="new-concept-two__shape-1 float-bob-y">
          <img src={Shape1} alt="" />
        </div>
        <div className="new-concept-two__shape-2 float-bob-x">
          <img src={Shape2} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <Fade left>
              <div className="col-xl-5 col-lg-6">
                <div className="new-concept-two__left">
                  <div className="section-title text-left">
                    <span className="section-title__tagline">
                      Good lifestyle
                    </span>
                    <h2 className="section-title__title">
                      MODERN SPACES & COMPLEXES
                    </h2>
                  </div>
                  <p className="new-concept__text">
                    There are many variations of passages of available, but the
                    majority have suffered alteration in some form.
                  </p>
                  <ul className="new-concept__points list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="icon-manhattan"></span>
                      </div>
                      <div className="text">
                        <h3>Luxury Living Experience</h3>
                        <p>
                          Services address a range of simply free aplication and
                          infrastructure needs.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-apartment-1"></span>
                      </div>
                      <div className="text">
                        <h3>Center At City Downtown</h3>
                        <p>
                          Services address a range of simply free aplication and
                          infrastructure needs.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="row mt-1">
                  <div className="col-md-12 d-flex justify-content-center">
                    <Link class="cta-two__btn thm-btn mt-2" to="/">
                      Check Out Services
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="col-xl-7 col-lg-6">
                <div className="new-concept-two__right">
                  <div className="new-concept-two__img">
                    <img src={Image4} alt="" />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section className="new-concept-two">
        <div className="new-concept-two__shape-1 float-bob-y">
          <img src={Shape2} alt="" />
        </div>
        <div className="new-concept-two__shape-2 float-bob-x">
          <img src={Shape1} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <Fade right>
              <div className="col-xl-7 col-lg-6">
                <div className="new-concept-two__right">
                  <div className="new-concept-two__img">
                    <img src={Image2} alt="" />
                  </div>
                </div>
              </div>
            </Fade>
            <Fade left>
              <div className="col-xl-5 col-lg-6">
                <div className="new-concept-two__left">
                  <div className="section-title text-left">
                    <span className="section-title__tagline">
                      Good lifestyle
                    </span>
                    <h2 className="section-title__title">
                      VALUES OF SMART LIVING
                    </h2>
                  </div>
                  <p className="new-concept__text">
                    There are many variations of passages of available, but the
                    majority have suffered alteration in some form.
                  </p>
                  <ul className="new-concept__points list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="icon-manhattan"></span>
                      </div>
                      <div className="text">
                        <h3>Luxury Living Experience</h3>
                        <p>
                          Services address a range of simply free aplication and
                          infrastructure needs.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-apartment-1"></span>
                      </div>
                      <div className="text">
                        <h3>Center At City Downtown</h3>
                        <p>
                          Services address a range of simply free aplication and
                          infrastructure needs.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="row mt-1">
                  <div className="col-md-12 d-flex justify-content-center">
                    <Link class="cta-two__btn thm-btn mt-2" to="/">
                      Check Out Services
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section className="new-concept-two">
        <div className="new-concept-two__shape-1 float-bob-y">
          <img src={Shape1} alt="" />
        </div>
        <div className="new-concept-two__shape-2 float-bob-x">
          <img src={Shape2} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <Fade left>
              <div className="col-xl-5 col-lg-6">
                <div className="new-concept-two__left">
                  <div className="section-title text-left">
                    <span className="section-title__tagline">
                      Good lifestyle
                    </span>
                    <h2 className="section-title__title">
                      ENJOY LUXURY RESIDENTIAL LIFE
                    </h2>
                  </div>
                  <p className="new-concept__text">
                    There are many variations of passages of available, but the
                    majority have suffered alteration in some form.
                  </p>
                  <ul className="new-concept__points list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="icon-manhattan"></span>
                      </div>
                      <div className="text">
                        <h3>Luxury Living Experience</h3>
                        <p>
                          Services address a range of simply free aplication and
                          infrastructure needs.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-apartment-1"></span>
                      </div>
                      <div className="text">
                        <h3>Center At City Downtown</h3>
                        <p>
                          Services address a range of simply free aplication and
                          infrastructure needs.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="row mt-1">
                  <div className="col-md-12 d-flex justify-content-center">
                    <Link class="cta-two__btn thm-btn mt-2" to="/">
                      Check Out Services
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="col-xl-7 col-lg-6">
                <div className="new-concept-two__right">
                  <div className="new-concept-two__img">
                    <img src={Image3} alt="" />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <Testimonials />
      <Brand />
      <section className="values-one">
        <div className="values-one__wrap">
          <Fade left>
            <div className="values-one__left">
              <div
                className="values-one__bg"
                style={{ backgroundImage: `url(${Image4})` }}
              ></div>
            </div>
          </Fade>
          <Fade right>
            <div className="values-one__right">
              <div className="values-one__shape-1 float-bob-x">
                <img src={Image5} alt="" />
              </div>
              <div className="values-one__content">
                <div className="section-title text-left">
                  <span className="section-title__tagline">
                    Apartments Amenities
                  </span>
                  <h2 className="section-title__title">
                    Values of smart living in amazon residence
                  </h2>
                </div>
                <p className="values-one__text">
                  Lorem ipsum simply free text available in the market. At vero
                  eos et accusamus et iusto odio dig ducimus qui blan ditiis sit
                  praesentium.
                </p>
                <div className="values-one__points-box">
                  <div className="values-one__points-img">
                    <img src={Image6} alt="" />
                  </div>
                  <ul className="values-one__points list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fa fa-check"></span>
                      </div>
                      <div className="text">
                        <p>Nsectetur cing elit</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="fa fa-check"></span>
                      </div>
                      <div className="text">
                        <p>Suspe ndisse suscipit leo</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="fa fa-check"></span>
                      </div>
                      <div className="text">
                        <p>Strong communication skills</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section className="team-one">
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">expert agents</span>
            <h2 className="section-title__title">meet property agents</h2>
          </div>
          <div className="row">
            <Fade bottom>
              <div className="col-xl-4 col-lg-4">
                <div className="team-one__single">
                  <div className="team-one__img-box">
                    <div className="team-one__img">
                      <img src={T1} alt="" />
                    </div>
                    <ul className="list-unstyled team-one__social-two">
                      <li>
                        <Link to="#">
                          <i className="fas fa-share-alt"></i>
                        </Link>
                      </li>
                    </ul>
                    <ul className="list-unstyled team-one__social">
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-linkedin-in"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-dribbble"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="team-one__content">
                    <h3 className="team-one__name">
                      <Link to="agents.html">Jessica brown</Link>
                    </h3>
                    <h5 className="team-one__sub-title">Architecture</h5>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="col-xl-4 col-lg-4">
                <div className="team-one__single">
                  <div className="team-one__img-box">
                    <div className="team-one__img">
                      <img src={T2} alt="" />
                    </div>
                    <ul className="list-unstyled team-one__social-two">
                      <li>
                        <Link to="#">
                          <i className="fas fa-share-alt"></i>
                        </Link>
                      </li>
                    </ul>
                    <ul className="list-unstyled team-one__social">
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-linkedin-in"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-dribbble"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="team-one__content">
                    <h3 className="team-one__name">
                      <Link to="agents.html">Jessica brown</Link>
                    </h3>
                    <h5 className="team-one__sub-title">Architecture</h5>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="col-xl-4 col-lg-4">
                <div className="team-one__single">
                  <div className="team-one__img-box">
                    <div className="team-one__img">
                      <img src={T3} alt="" />
                    </div>
                    <ul className="list-unstyled team-one__social-two">
                      <li>
                        <Link to="#">
                          <i className="fas fa-share-alt"></i>
                        </Link>
                      </li>
                    </ul>
                    <ul className="list-unstyled team-one__social">
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-linkedin-in"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-dribbble"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="team-one__content">
                    <h3 className="team-one__name">
                      <Link to="agents.html">Jessica brown</Link>
                    </h3>
                    <h5 className="team-one__sub-title">Architecture</h5>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <Contact/>
    </>
  );
};
export default Aboutus;
