import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BackToTop from "./component/BackToTop";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Aboutus from "./pages/Aboutus";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Accomodation from "./pages/Accomodation";
import Review from "./pages/Reviews";
import Landlord from "./pages/services/Landlord";
import Companies from "./pages/services/Companies";
import Investors from "./pages/services/Investors";
import Services from "./pages/services/services";

const App = () => {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/faq" element={<Faq />}></Route>
          <Route exact path="/about" element={<Aboutus />}></Route>
          <Route exact path="/contact" element={<Contact />}></Route>
          <Route exact path="/accomodation" element={<Accomodation />}></Route>
          <Route exact path="/review" element={<Review />}></Route>
          <Route exact path="/landlord" element={<Landlord />}></Route>
          <Route exact path="/companies" element={<Companies />}></Route>
          <Route exact path="/investors" element={<Investors />}></Route>
          <Route exact path="/services" element={<Services />}></Route>
        </Routes>
        <BackToTop />
        <Footer />
      </Router>
    </div>
  );
};

export default App;
