import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Menu from "./Menu";
import Logo from "../images/deluxe-0shadow1.png";
const StickyHeader = () => {
  const Tooggle = (e) => {
    $(".mobile-nav__wrapper").toggleClass("expanded");
  };

  return (
    <>
      <div className="main-menu__wrapper">
        <div className="main-menu__wrapper-inner">
          <div className="main-menu__left">
            <div className="main-menu__logo">
              <Link to="/">
                <img src={Logo} alt="Deluxe" className="w-50"  />
              </Link>
            </div>
          </div>
          <div className="main-menu__main-menu-box">
            <Link to="#" className="mobile-nav__toggler" onClick={Tooggle}>
              <i className="fa fa-bars"></i>
            </Link>
            <Menu />
          </div>
          {/* <div className="main-menu__right">
            <div className="main-menu__search-cart-btn">
              <div className="main-menu__btn-box">
                <Link to="/" className="thm-btn main-menu__btn">
                  Book Now
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default StickyHeader;
