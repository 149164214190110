import React from "react";
import { Link } from "react-router-dom";
const Menu = () => {
  const Scroll = () => {
    window.scroll(0, 0);
  };

  return (
    <>
      <ul className="main-menu__list">
        <li>
          <Link to="/" onClick={Scroll}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={Scroll}>
            About Us
          </Link>
        </li>
        <li>
          <Link to="/accomodation" onClick={Scroll}>
            Accomodation{" "}
          </Link>
        </li>
        <li class="dropdown">
          <Link to="/services" onClick={Scroll}>
            All Services
          </Link>
          <ul class="shadow-box">
            <li>
              <Link to="/landlord" onClick={Scroll}>
                Landlord
              </Link>
            </li>
            <li>
              <Link to="/investors" onClick={Scroll}>
                Investors
              </Link>
            </li>
            <li>
              <Link to="/companies" onClick={Scroll}>
                Companies
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/contact" onClick={Scroll}>
            Contact us
          </Link>
        </li>
        <li>
          <Link to="/review" onClick={Scroll}>
            Reviews{" "}
          </Link>
        </li>
        <li>
          <Link to="/faq" onClick={Scroll}>
            Faq
          </Link>
        </li>
      </ul>
    </>
  );
};
export default Menu;
