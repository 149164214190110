import PageHeader from "../../component/PageHeader";
import Image1 from "../../images/services/landlord.jpg";
import Brand from "../../component/Brand";
import Testimonials from "../../component/Testimonials";
import Shape1 from "../../images/shapes/cta-three-shape-1.png";
import T1 from "../../images/team/team-1-1.jpg";
import T2 from "../../images/team/team-1-2.jpg";
// import T3 from "../../images/team/team-1-3.jpg";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import Contact from "../../component/Contact";
const Landlord = () => {
  return (
    <>
      <PageHeader img={Image1} heading="Landlord" />
      <Brand />
      <section class="team-one">
        <div class="container">
          <div class="section-title text-center">
            <span class="section-title__tagline">landlord</span>
            <h2 class="section-title__title">landlords</h2>
          </div>
          <div className="row">
            <Fade left>
              <div className="col-xl-4 col-lg-4">
                <div className="about-building__left">
                  <div className="about-building__img">
                    <img src={T1} alt="" />
                  </div>
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="col-xl-8 col-lg-8">
                <div className="about-building__right">
                  <br />
                  <br />
                  <div className="section-title text-left">
                    <h2 className="section-title__title">
                      Lorem ipsum Lorem ipsum{" "}
                    </h2>
                  </div>
                  <p className="about-building__text-1">
                    Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem
                    ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem
                    ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem
                    ipsumLorem ipsum
                  </p>
                  <p className="about-building__text-2">
                    Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem
                    ipsumLorem ipsum
                  </p>
                  {/* <a
                    class="cta-two__btn thm-btn mt-2"
                    href="https://apartmentsde-luxe.zeevou.direct/"
                  >
                    Book Now
                  </a> */}
                </div>
              </div>
            </Fade>
          </div>
          <div className="row">
            <Fade left>
              <div className="col-xl-8 col-lg-8">
                <div className="about-building__right">
                  <br />
                  <br />
                  <div className="section-title text-left">
                    <h2 className="section-title__title">
                      Lorem ipsum Lorem ipsum{" "}
                    </h2>
                  </div>
                  <p className="about-building__text-1">
                    Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem
                    ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem
                    ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem
                    ipsumLorem ipsum
                  </p>
                  <p className="about-building__text-2">
                    Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem
                    ipsumLorem ipsum
                  </p>
                  {/* <a
                    class="cta-two__btn thm-btn mt-2"
                    href="https://apartmentsde-luxe.zeevou.direct/"
                  >
                    Book Now
                  </a> */}
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="col-xl-4 col-lg-4">
                <div className="about-building__left">
                  <div className="about-building__img">
                    <img src={T2} alt="" />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <Testimonials />
      <section className="cta-three">
        <div className="cta-three__shape-1 float-bob-x">
          <img src={Shape1} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-8">
              <div className="cta-three__left">
                <p className="cta-three__sub-title">
                  Don’t hestitate to contact us
                </p>
                <h3 className="cta-three__title">Book now</h3>
              </div>
            </div>
            <div className="col-xl-6 col-lg-4">
              <div className="cta-three__btn-box">
                <Link to="/" className="thm-btn cta-three__btn">
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br/>
      <Contact/>
    </>
  );
};
export default Landlord;
