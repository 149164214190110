import React from "react";
import { Link } from "react-router-dom";
import Img1 from "../images/shapes/site-footer-shape-1.png";
import Img2 from "../images/deluxe-0shadow1.png";
const Footer = () => {
  return (
    <>
      {/* <!--Site Footer Start--> */}
      <footer className="site-footer">
        <div className="site-footer__shape-1 float-bob-x">
          <img src={Img1} alt="" />
        </div>
        <div className="container">
          <div className="site-footer__inner">
            <div className="site-footer__top">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="footer-widget__column footer-widget__about">
                    <div className="footer-widget__logo">
                      <Link to="/">
                        <img src={Img2} alt="" className="w-100" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="footer-widget__column footer-widget__explore">
                    <div className="footer-widget__title-box">
                      <h3 className="footer-widget__title">explore</h3>
                    </div>
                    <div className="footer-widget__explore-list-box">
                      <ul className="footer-widget__explore-list list-unstyled">
                        <li>
                          <Link to="/">About Us</Link>
                        </li>
                        <li>
                          <Link to="/">Our Appartments</Link>
                        </li>
                        <li>
                          <Link to="/">Gallery</Link>
                        </li>
                        <li>
                          <Link to="/">Review</Link>
                        </li>
                        <li>
                          <Link to="/">Faq</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="footer-widget__column footer-widget__contact">
                    <div className="footer-widget__title-box">
                      <h3 className="footer-widget__title">Contact</h3>
                    </div>
                    <p className="footer-widget__contact-text">
                      30 Road Broklyn Street, 600 <br /> New York, USA
                    </p>
                    <ul className="footer-widget__contact-list list-unstyled">
                      <li>
                        <div className="icon">
                          <span className="fas fa-envelope"></span>
                        </div>
                        <div className="text">
                          <p>
                            <Link to="mailto:info@deluxe.com">
                              info@deluxe.com
                            </Link>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="icon-telephone"></span>
                        </div>
                        <div className="text">
                          <p>
                            <Link to="tel:+9288006780">
                              +92 ( 8800 ) - 6780
                            </Link>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="footer-widget__column footer-widget__newsletter">
                    <div className="footer-widget__title-box">
                      <h3 className="footer-widget__title">newsletter</h3>
                    </div>
                    <form className="footer-widget__email-box">
                      <div className="footer-widget__email-input-box">
                        <input
                          type="email"
                          placeholder="Email Address"
                          name="email"
                        />
                      </div>
                      <button
                        type="submit"
                        className="footer-widget__subscribe-btn thm-btn"
                      >
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="site-footer__bottom">
              <div className="site-footer__bottom-inner">
                <div className="site-footer__bottom-left">
                  <p className="site-footer__bottom-text">
                    © All Copyright 2023 by <Link to="#">Deluxes</Link> |
                    Powered By <Link to="#">HnH Tech Solutions</Link>
                  </p>
                </div>
                <div className="site-footer__bottom-right">
                  <div className="site-footer__social">
                    {/* <Link to="#">
                      <i className="fab fa-twitter"></i>
                    </Link> */}
                    <Link to="#">
                      <i className="fab fa-facebook"></i>
                    </Link>
                    <Link to="#">
                      <i className="fab fa-pinterest-p"></i>
                    </Link>
                    <Link to="#">
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!--Site Footer End--> */}
    </>
  );
};
export default Footer;
