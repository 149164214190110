import PageHeader from "../../component/PageHeader";
import Image1 from "../../images/services/Companies.jpg";
import Image2 from "../../images/resources/about-1-1.jpg";
import Image3 from "../../images/shapes/about-one-shape-1.png";
import Image4 from "../../images/shapes/about-one-shape-2.png";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Image5 from "../../images/backgrounds/counter-one-bg-img-1.png";
import Contact from "../../component/Contact";
import Image6 from "../../images/resources/about-1-2.png";

const Companies = () => {
  return (
    <>
      <PageHeader img={Image1} heading="Companies" />
      <section className="about-one">
        <div className="about-one__img-two">
          <img src={Image6} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <Fade left>
              <div className="col-xl-6">
                <div className="about-one__left">
                  <div
                    className="about-one__img-box wow slideInLeft"
                    data-wow-delay="100ms"
                    data-wow-duration="2500ms"
                  >
                    <div className="about-one__img">
                      <img src={Image2} alt="" />
                    </div>
                    <div className="about-one__shape-1 shape-mover">
                      <img src={Image3} alt="" />
                    </div>
                    <div className="about-one__big-text">Deluxe</div>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="col-xl-6">
                <div className="about-one__right">
                  <div className="section-title text-left">
                    <span className="section-title__tagline">
                      Companies overview
                    </span>
                    <h2 className="section-title__title">Company</h2>
                  </div>
                  <p className="about-one__text-1">
                    Lorem ipsum ipsum ipsum ipsum ipsum ipsum{" "}
                  </p>
                  <p className="about-one__text-2">
                    Lorem ipsum ipsum ipsum ipsum ipsum ipsum Lorem ipsum ipsum
                    ipsum ipsum ipsum ipsum Lorem ipsum ipsum ipsum ipsum ipsum
                    ipsum Lorem ipsum ipsum ipsum ipsum ipsum ipsum{" "}
                  </p>
                  <ul className="about-one__points-list list-unstyled">
                    <li>
                      <div className="about-one__points-list-content">
                        <div className="icon">
                          <span className="fa fa-check"></span>
                        </div>
                        <div className="text">
                          <h3>Water Storage</h3>
                        </div>
                      </div>
                      <p className="about-one__points-text">
                        Lorem ipsum dolor sited amet consectetur notted.
                      </p>
                    </li>
                    <li>
                      <div className="about-one__points-list-content">
                        <div className="icon">
                          <span className="fa fa-check"></span>
                        </div>
                        <div className="text">
                          <h3>solar systems</h3>
                        </div>
                      </div>
                      <p className="about-one__points-text">
                        Lorem ipsum dolor sited amet consectetur notted.
                      </p>
                    </li>
                  </ul>
                  <div className="about-one__btn-box">
                    <div className="about-one__shape-2 float-bob-x">
                      <img src={Image4} alt="" />
                    </div>
                    <Fade bottom>
                      <Link to="/" className="about-one__btn thm-btn">
                        More details
                      </Link>
                    </Fade>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <div className="counter-one">
        <div
          className="counter-one__bg float-bob-x"
          style={{ backgroundImage: `url(${Image5})` }}
        ></div>
        <div className="container">
          <div className="row">
            <Fade bottom>
              {/* <!--Counter One Single Start--> */}
              <div
                className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div className="counter-one__single">
                  <div className="counter-one__top">
                    <div className="counter-one__shape"></div>
                    <div className="counter-one__icon">
                      <span className="icon-ruler"></span>
                    </div>
                  </div>
                  <div className="counter-one__count-box">
                    <h3 className="odometer">
                      <CountUp end={1200} redraw={true}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </h3>
                    <p className="counter-one__text">HAPPY CUSTOMER</p>
                  </div>
                </div>
              </div>
            </Fade>
            {/* <!--Counter One Single End--> */}
            <Fade bottom>
              {/* <!--Counter One Single Start--> */}
              <div
                className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="200ms"
              >
                <div className="counter-one__single">
                  <div className="counter-one__top">
                    <div className="counter-one__shape"></div>
                    <div className="counter-one__icon">
                      <span className="icon-car-parking"></span>
                    </div>
                  </div>
                  <div className="counter-one__count-box">
                    <h3 className="odometer">
                      <CountUp end={100} redraw={true}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </h3>
                    <p className="counter-one__text">CURRENT CUSTOMER</p>
                  </div>
                </div>
              </div>
            </Fade>

            {/* <!--Counter One Single End--> */}
            <Fade bottom>
              {/* <!--Counter One Single Start--> */}
              <div
                className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="300ms"
              >
                <div className="counter-one__single">
                  <div className="counter-one__top">
                    <div className="counter-one__shape"></div>
                    <div className="counter-one__icon">
                      <span className="icon-condominium"></span>
                    </div>
                  </div>
                  <div className="counter-one__count-box">
                    <h3 className="odometer">
                      <CountUp end={500} redraw={true}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </h3>
                    <p className="counter-one__text">SATISFIED CUSTOMER</p>
                  </div>
                </div>
              </div>
            </Fade>

            {/* <!--Counter One Single End--> */}
            {/* <!--Counter One Single Start--> */}
            <Fade bottom>
              <div
                className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="400ms"
              >
                <div className="counter-one__single">
                  <div className="counter-one__top">
                    <div className="counter-one__shape"></div>
                    <div className="counter-one__icon">
                      <span className="icon-bedroom"></span>
                    </div>
                  </div>
                  <div className="counter-one__count-box">
                    <h3 className="odometer">
                      <CountUp end={300} redraw={true}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </h3>
                    <p className="counter-one__text">YET TO BOOK WITH US</p>
                  </div>
                </div>
              </div>
            </Fade>

            {/* <!--Counter One Single End--> */}
          </div>
        </div>
      </div>
      <Contact />
    </>
  );
};
export default Companies;
