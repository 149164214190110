import React from "react";
import PageHeader from "../../component/PageHeader";
import Image1 from "../../images/services/services.png";
import Image2 from "../../images/resources/feature-1-1.jpg";
import Image3 from "../../images/resources/feature-1-2.jpg";
import { Link } from "react-router-dom";
import Brand from "../../component/Brand";
import Testimonials from "../../component/Testimonials";
import { Fade } from "react-reveal";
import Image14 from "../../images/backgrounds/about-building-bg.png";
import Image15 from "../../images/resources/about-building-img-1.jpg";
import Contact from "../../component/Contact";

const Services = () => {
  return (
    <>
      <PageHeader img={Image1} heading="Services" />
      <section className="about-building" style={{ padding: " 120px 0 0px" }}>
        <div
          className="about-building__bg"
          style={{ backgroundImage: `url(${Image14})` }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <Fade left>
                <div className="about-building__left">
                  <div className="about-building__img">
                    <img src={Image15} alt="" />
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-xl-6 col-lg-6">
              <Fade right>
                <div className="about-building__right">
                  <div className="section-title text-left">
                    <h2 className="section-title__title">Service One</h2>
                  </div>
                  <p className="about-building__text-1">
                    There are many variations of passages of psum available, but
                    the majority have suffered alteration in some form, by
                    injected humour, or randomised words which don't look even
                    slightly believable.
                  </p>
                  <p className="about-building__text-2">
                    If you are going to use a passage of you need to be sure
                    there isn't anything embarrassing hidden in the middle of
                    text.
                  </p>
                  <Link class="cta-two__btn thm-btn mt-2" to="/">
                    Read More
                  </Link>
                </div>
              </Fade>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <Fade right>
                <div className="about-building__right">
                  <div className="section-title text-left">
                    <h2 className="section-title__title">Service Two</h2>
                  </div>
                  <p className="about-building__text-1">
                    There are many variations of passages of psum available, but
                    the majority have suffered alteration in some form, by
                    injected humour, or randomised words which don't look even
                    slightly believable.
                  </p>
                  <p className="about-building__text-2">
                    If you are going to use a passage of you need to be sure
                    there isn't anything embarrassing hidden in the middle of
                    text.
                  </p>
                  <Link class="cta-two__btn thm-btn mt-2" to="/">
                    Read More
                  </Link>
                </div>
              </Fade>
            </div>
            <div className="col-xl-6 col-lg-6">
              <Fade left>
                <div className="about-building__left">
                  <div className="about-building__img">
                    <img src={Image2} alt="" />
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <Fade left>
                <div className="about-building__left">
                  <div className="about-building__img">
                    <img src={Image3} alt="" />
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-xl-6 col-lg-6">
              <Fade right>
                <div className="about-building__right">
                  <div className="section-title text-left">
                    <h2 className="section-title__title">Service Three</h2>
                  </div>
                  <p className="about-building__text-1">
                    There are many variations of passages of psum available, but
                    the majority have suffered alteration in some form, by
                    injected humour, or randomised words which don't look even
                    slightly believable.
                  </p>
                  <p className="about-building__text-2">
                    If you are going to use a passage of you need to be sure
                    there isn't anything embarrassing hidden in the middle of
                    text.
                  </p>
                  <Link class="cta-two__btn thm-btn mt-2" to="/">
                    Read More
                  </Link>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <Brand />
      <Testimonials />
      <Contact/>
    </>
  );
};
export default Services;
