import React from "react";
import PageHeader from "../component/PageHeader";
import Image1 from "../images/backgrounds/contact.jpg";
import Image2 from "../images/shapes/location-shape-1.png";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
const Contact = () => {
  return (
    <>
      <PageHeader img={Image1} heading="Contact Us" />
      <section className="contact-page">
        <div className="container">
          <Fade right>
            <div className="section-title text-center">
              <span className="section-title__tagline">
                get in touch with us
              </span>
              <h2 className="section-title__title">Write us anytime</h2>
            </div>
          </Fade>

          <Fade bottom>
            <div className="contact-page__inner">
              <form
                action=""
                className="contact-page__form contact-form-validated"
              >
                <div className="row">
                  <div className="col-xl-6">
                    <div className="contact-page__input-box">
                      <input type="text" placeholder="Your Name" name="name" />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="contact-page__input-box">
                      <input
                        type="email"
                        placeholder="Email Address"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="contact-page__input-box">
                      <input
                        type="text"
                        placeholder="Phone Number"
                        name="phone"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="contact-page__input-box">
                      <input type="text" placeholder="Subject" name="Subject" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="contact-page__input-box text-message-box">
                      <textarea
                        name="message"
                        placeholder="Write Message"
                      ></textarea>
                    </div>
                    <div className="contact-page__btn-box">
                      <button
                        type="submit"
                        className="thm-btn contact-page__btn"
                      >
                        Send a message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Fade>
        </div>
      </section>
      <section className="location">
        <div className="location-shape-1 float-bob-x">
          <img src={Image2} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <Fade bottom>
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="location__single">
                  <h4 className="location__title">Austin</h4>
                  <p className="location__text">22 Texas West Hills</p>
                  <div className="location__phone-email">
                    <Link to="mailto:info@deluxe.com.com">
                      info@deluxe.com.com
                    </Link>{" "}
                    <br />
                    <Link to="tel:9200888690">+92 (0088) - 8690</Link>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="location__single">
                  <h4 className="location__title">boston</h4>
                  <p className="location__text">5 Federal Street Boston</p>
                  <div className="location__phone-email">
                    <Link to="mailto:info@deluxe.com.com">
                      info@deluxe.com.com
                    </Link>{" "}
                    <br />
                    <Link to="tel:9200888690">+92 (0088) - 8690</Link>
                  </div>
                </div>
              </div>
            </Fade>

            <Fade bottom>
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="location__single">
                  <h4 className="location__title">new york</h4>
                  <p className="location__text">8th Broklyn New York</p>
                  <div className="location__phone-email">
                    <Link to="mailto:info@deluxe.com.com">
                      info@deluxe.com.com
                    </Link>{" "}
                    <br />
                    <Link to="tel:9200888690">+92 (0088) - 8690</Link>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="location__single">
                  <h4 className="location__title">baltimore</h4>
                  <p className="location__text">3 Lombabr 50 baltimore</p>
                  <div className="location__phone-email">
                    <Link to="mailto:info@deluxe.com.com">
                      info@deluxe.com.com
                    </Link>{" "}
                    <br />
                    <Link to="tel:9200888690">+92 (0088) - 8690</Link>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
