import React from "react";
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import Img1 from "../images/gallery/gallery-1-1.jpg";
import Img2 from "../images/gallery/gallery-1-2.jpg";
import Img3 from "../images/gallery/gallery-1-3.jpg";
import Img4 from "../images/gallery/gallery-1-4.jpg";
import Img5 from "../images/gallery/gallery-1-5.jpg";
const Gallery = () => {
  const Data = [
    {
      img: Img1,
      title: "Appartment",
      name: "Luxury Appartment",
    },
    {
      img: Img2,
      title: "Appartment",
      name: "Luxury Appartment",
    },
    {
      img: Img3,
      title: "Appartment",
      name: "Luxury Appartment",
    },
    {
      img: Img4,
      title: "Appartment",
      name: "Luxury Appartment",
    },
    {
      img: Img5,
      title: "Appartment",
      name: "Luxury Appartment",
    },
  ];
  return (
    <>
      <section className="gallery-one">
        <div className="container">
          <div className="gallery-one__carousel owl-carousel owl-theme thm-owl__carousel owl-loaded owl-drag">
            <Swiper
              spaceBetween={20}
              slidesPerView={5}
              loop={true}
              autoplay={{ delay: 5000 }}
              speed={1400}
              loopFillGroupWithBlank={true}
              modules={[Autoplay]}
              className="owl-stage-outer"
            >
              <div className="owl-stage">
                {Data.map((e) => (
                  <SwiperSlide style={{Width : "247.8px"}}>
                    <div className="item">
                      <div className="gallery-one__single">
                        <div className="gallery-one__img-box">
                          <div className="gallery-one__img">
                            <img src={e.img} alt="" />
                          </div>
                          <div className="gallery-one__content">
                            <p className="gallery-one__sub-title">{e.title}</p>
                            <h3 className="gallery-one__title">
                              <Link to="apartment-details-1.html">
                                {e.name}
                              </Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>

            {/* <div className="owl-nav disabled">
              <button type="button" role="presentation" className="owl-prev">
                <span className="fa fa-angle-left"></span>
              </button>
              <button type="button" role="presentation" className="owl-next">
                <span className="fa fa-angle-right"></span>
              </button>
            </div>
            <div className="owl-dots disabled"></div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
